<template>
	<div class="user-layout">
		<router-view/>
	</div>
</template>


<script>
	export default {
		name: 'UserLayout',
	};
</script>

<style lang="scss" scoped>

	.user-layout{
		min-height: 100vh;
	}

</style>

