import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Page/Home.vue'
import UserLayout from '../views/Layout/User.vue';
import PcLayout from '../views/Layout/Pc.vue';
import Pc from '../views/Page/Pc.vue';


Vue.use(VueRouter);

let router = new VueRouter({
	mode: 'history',
	routes: [
    {
			path: '/',
			component: UserLayout,
			children: [
          {
          path: '/',
          name: 'Home',
          component: Home
        },  
			]
		},
    {
			path: '/',
			component: PcLayout,
			children: [
				{
					path: 'pc',
					name: 'pc',
					component: Pc
				},
			]
		},
  ]
});

export default router;