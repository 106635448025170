import axios from 'axios';


const instance = axios.create({
	//baseURL: 'https://some-domain.com/api/',
	//timeout: 1000,
	//headers: {'X-Custom-Header': 'foobar'},
	/*transformResponse: [function (data) {
	 // Do whatever you want to transform the data
	 //console.log(a,b)
	 //if (data)
	 return data;
	 }]*/
});


/*axios(
 {
 method: "POST",
 "headers":
 {
 "content-type": "application/x-www-form-urlencoded",
 "authorization": "Basic " + sessionStorage.getItem('id_token')--id_token is my token variable name
 }
 }).then
 (
 items = > {
 this.response = result.data;
 },
 error =>
 {
 console.error(error);
 this.error = error;
 }
 )
 ;*/


// Add a response interceptor
instance.interceptors.response.use(function(response)
{
	// Do something with response data
	//document.querySelector('.header > .spinner').classList.remove('show')
	return response;
}, function(error)
{
	// Do something with response error
	//document.querySelector('.header > .spinner').classList.remove('show')

	/*
	 console.log(error.response)
	 */

	if (error.response.status == 401)
	{
		window.app.$router.push({name: 'login', params:{ file : false }});
	}

	//console.log(error.response.status)
	return Promise.reject(error);
});

instance.interceptors.request.use(function(config)
{
	// Do something before request is sent
	config.headers = {
		"X-Requested-With": "XMLHttpRequest",
	};

	if (localStorage.getItem("token"))
	{
		config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("token");
	}
	//document.querySelector('.header > .spinner').classList.add('show')
	return config;
}, function(error)
{
	// Do something with request error
	return Promise.reject(error);
});


export default instance;