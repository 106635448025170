<template>
	<div>
		<div class="background">
			<div class="right">
				<img height="700px" width="550px" :src="require('@/assets/error.jpg')">
			</div>
			<div class="left">
				<div class="icon">
					<svg fill="#ff008a" width="150px" height="150px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g>
	<g>
		<path d="M366,0H146c-20.678,0-37.5,16.822-37.5,37.5v437c0,20.678,16.822,37.5,37.5,37.5h220c20.678,0,37.5-16.822,37.5-37.5v-437
			C403.5,16.822,386.678,0,366,0z M388.5,407H176c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h212.5v52.5
			c0,12.407-10.093,22.5-22.5,22.5H146c-12.407,0-22.5-10.093-22.5-22.5V422H146c4.142,0,7.5-3.358,7.5-7.5
			c0-4.142-3.358-7.5-7.5-7.5h-22.5V75h265V407z M388.5,60h-265V37.5c0-12.407,10.093-22.5,22.5-22.5h220
			c12.407,0,22.5,10.093,22.5,22.5V60z"/>
	</g>
</g><g>
	<g>
		<path d="M286,30h-30c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h30c4.142,0,7.5-3.358,7.5-7.5
			C293.5,33.358,290.142,30,286,30z"/>
	</g>
</g><g>
	<g>
		<path d="M256,437c-12.407,0-22.5,10.093-22.5,22.5c0,12.407,10.093,22.5,22.5,22.5s22.5-10.093,22.5-22.5
			C278.5,447.093,268.407,437,256,437z M256,467c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5
			S260.136,467,256,467z"/>
	</g>
</g><g>
						<g>
							<circle cx="226" cy="37.5" r="7.5"/>
						</g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g><g>
					</g></svg>
				</div>
				<div class="text">
					<div class="text-helper">Please browse this webapp by your mobile phone</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'Pc',

		methods : {
			isMobile() {
				if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					return true
				} else {
					return false
				}
			}
		},
		mounted(){
			if (this.isMobile())
			{
				this.$router.push({name: 'Home'});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clearfix
	{
		clear: both;
	}

	.background
	{
		height: 100vh;
	}

	.right
	{
		float: right;
		width: 50%;
		height: 100%;
	}

	.left
	{
		float: left;
		width: 50%;
		height: 100%;
	}

	.icon{
		margin-top: 30%;
		text-align: center;
		}

	.text{
		margin-top: 15%;
	}
	.text-helper
	{
		margin: auto;
		left: 0;
		right: 0;
		width: 400px;
		font-size: 30px;
		font-weight: bold;
	}
</style>
