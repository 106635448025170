<template>
  <div class="home">
    <div class="header">
      <span class="header-text" >TALOS </span>
      <span class="header-text" style="color : #FF008A"> EMOTION</span>
    </div>

    <div class="item">
        <div :class="['linkdin', type == 'linkdin' ? 'select' : '']" @click="linkdin()">IMAGE ADDRESS</div>
        <div :class="['upload', type == 'upload' ? 'select' : '']" @click="upload()">UPLOAD IMAGE</div>
    </div>

    <div v-if="type == 'upload'" class="file-helper">
 
        <div class="error">
            {{error ? error.img[0] : ''}}
        </div>
 
        <div class="loading" v-show="loadingUpload">
              <RingLoader color="#ff008a" duration=".8s"/>
        </div>			

        <div v-show="!loadingUpload">
          <div ontouchstart="" class="file" @click="$refs.fileInput.click()" v-show="!image && showSelectFile">
            <input @change="selectFile" type="file" ref="fileInput" hidden>
            <div class="txt">Upload</div>
            <div class="icon">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 489.4 489.4" style="enable-background:new 0 0 489.4 489.4;" xml:space="preserve"><path d="M382.4,422.75h-79.1H282h-4.6v-106.1h34.7c8.8,0,14-10,8.8-17.2l-67.5-93.4c-4.3-6-13.2-6-17.5,0l-67.5,93.4c-5.2,7.2-0.1,17.2,8.8,17.2h34.7v106.1h-4.6H186H94.3c-52.5-2.9-94.3-52-94.3-105.2c0-36.7,19.9-68.7,49.4-86c-2.7-7.3-4.1-15.1-4.1-23.3c0-37.5,30.3-67.8,67.8-67.8c8.1,0,15.9,1.4,23.2,4.1c21.7-46,68.5-77.9,122.9-77.9c70.4,0.1,128.4,54,135,122.7c54.1,9.3,95.2,59.4,95.2,116.1C489.4,366.05,442.2,418.55,382.4,422.75z"/></svg>
            </div>
          </div>

          <div class="preview-image" v-show="image">
            <img class="img" :src="image">
          </div>
        </div>
  
        <div v-if="resultLink != null">
          <img class="result-image" :src="resultLink">
        </div>

        <div :class="['button', image ? 'two-button' :'']">
          <div :class="['btn', image ? 'small-analyze' : '']" @click="getData()">ANALYZE</div>
          <div :class="['btn new-btn', image ? 'small-analyze' : '']" v-show="image" @click="newImage()">NEW ANALYZE</div>
        </div>

        <div class="button" v-if="resultLink != null">
          <div class="btn new-btn" @click="newImage()">NEW ANALYZE</div>
        </div>
		</div>

    <div class="file-helper" v-if="type == 'linkdin'">
        <div class="error">
            {{error ? error.url[0] : ''}}
        </div>
        <div class="loading" v-show="loadingLink">
              <RingLoader color="#ff008a" duration=".8s"/>
        </div>
        <div class="inp" v-if="resultLink == null && !loadingLink">
          <input class="input" v-model="url" placeholder="Image Url" >
        </div>

        <div class="button" v-if="resultLink == null && !loadingLink">
          <div class="btn" @click="getData()">ANALYZE</div>
        </div>
        <div v-if="resultLink != null">
          <img class="result-image" :src="resultLink">
        </div>
        <div class="button" v-if="resultLink != null">
              <div class="btn new-btn" @click="newImage()">NEW ANALYZE</div>
        </div>
    </div>

    <div class="footer">
        <div class="footer-helper">
          <a class="footer-img" href="https://aitalos.com" target="blank">
            <img src="img/logo-footer-report.png" >
          </a>
          <div class="third">AITALOS developed an artificial intelligence (AI) solutions in predicting breast anomaly.</div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import setting from './../../setting.js';
import axios from './../../Xhr.js';

export default {
  name: 'Home',

  data(){
    return {
      type : 'linkdin',
      resultLink : null,
      loadingLink :false,
      url : '',
      base64 : '',
      loadingUpload : false,
      image: null,
      showSelectFile: true,
      error : null
    }
  },
  methods : {
      linkdin(){
        this.type = 'linkdin';
      },
      upload(){
        this.type = 'upload';
      },
      getData(){
				let vm = this
        if(this.type == 'linkdin'){
          if(vm.url != ''){
              vm.loadingLink = true;
              axios.post(setting.panelUrl + 'emotion/insert', {
                'url' : vm.url,
                'type' : 'URL'
                }).then(function(res)
                {
                  console.log(res,'url')
                  vm.resultLink = 'data:image/jpeg;base64,'+res.data.edata[0].img
                  console.log(vm.resultLink)
                  vm.loadingLink = false;
                  vm.error = null;
                }).catch(function(res)
                {
                  vm.loadingLink = false;
                  vm.error = res.response.data.errors;
                  console.log(res.response.data.message)
                }
            );
          }else{
            vm.error = {
              url : [
                'Url is required'
              ]
            }
           }
        }else{
          if(vm.image != null){
            vm.loadingUpload = true;
            var formData   = new FormData();
            formData.append("img", this.$parent.file);
            formData.append("type", 'IMG');
              axios.post(setting.panelUrl + 'emotion/insert', formData, {
                headers: {
                  'content-type': 'multipart/form-data'
                } 
                }).then(function(res)
                {
                  vm.image = null;
                  vm.showSelectFile = false
                  vm.resultLink = 'data:image/jpeg;base64,'+res.data.edata[0].img
                  vm.loadingUpload = false;
                  vm.error = null;
                  if(res.data.status == false){
                    vm.showSelectFile = true
                    vm.resultLink = null
                    vm.error = {
                      img : [
                        'Your image is invalid'
                      ]
                    }
                  }
                }).catch(function(res)
                {
                  vm.showSelectFile = true
                  vm.resultLink = null
                  vm.image = null;
                  vm.loadingUpload = false;
                  vm.error = res.response ? res.response.data.errors : '';
                }
            );
          }else{
            vm.error = {
              img : [
                'image is required'
              ]
            }
          }
        }
      },
      newImage(){
        this.resultLink = null;
        this.showSelectFile = true;
        this.image = null;
        this.$refs.fileInput.value = null
      },
      isMobile() {
				if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					return true
				} else {
					return false
				}
			},
      selectFile(e)
			{
				let vm = this;
        console.log(e)
				this.$parent.file = e.target.files[0];
				this.image        = URL.createObjectURL(this.$parent.file);
				var reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = function()
				{
					vm.base64 = reader.result;
				};
			}
  },
  mounted(){
    if (!this.isMobile())
			{
				this.$router.push({name: 'pc'});
			}
  }
}
</script>


<style lang="scss" scoped>
  .home{
    height: 100vh;
    position: relative;
    text-align: center;

  .link{
    height: 400px;
  }

    .file-helper
	{
		height: 380px;
		padding: 5% 5%;
    padding-bottom: 0 !important;
    position: relative;

		.file
		{
			height: 100%;
			border: 1px solid #CACACA;
			border-radius: 3px;
			background: rgb(2, 0, 36);
			background: linear-gradient(0deg, rgba(2, 0, 36, 0.16850490196078427) 0%, rgba(255, 255, 255, 0.3085609243697479) 100%);
			.txt
			{
				margin-top: 50px;
				margin-bottom: 25px;
				text-align: center;
				font-weight: bold;
				color: #818181;
				font-size: 16px;
				user-select: none;
			}
			.icon
			{
				width: 30%;
				margin-left: 35%;
			}
		}
		.file:active
		{
			mix-blend-mode: difference;
		}
		.img
		{
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			border-radius: 3px;
		}
	}

  }

  .header-text{
    font-weight : bolder;
    font-size : 30px;
  }

  .header{
    border-bottom: 1px solid grey;
    width : 100%;
    padding: 10px 0 ;
  }

  .item{
    display: flex;
    margin-top: 15px;
  }

  .linkdin{
    flex: 1;
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
  }

  .upload{
    flex: 1;
    font-size: 15px;
    padding: 5px;
    font-weight: bolder;
  }

  .select{
    border-bottom: 2px solid black;
  }

  .inp{
    margin-top: 80px;
    height: 40px;
  }

  .input{
    width: 92%;
    padding: 12px 20px;
    margin: 8px 4%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
   }

  .button{
    position: absolute;
    bottom: -20px !important;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
  }

  .btn{
    background-color: #FF008A;
    padding: 10px;
    font-weight: 900;
    color:white;
    border-radius: 8px;
   }

  .new-btn{
    background-color: #000000 !important;
  }

  .footer{
    position: absolute;
    bottom: 0;
    height: 25%;
    width: 100%;
    border-top: 1px solid grey;
    display: flex;
    align-items: center;
  }
  .footer-img{
    img{
      height: 7%;
      width: 40%;
      margin-bottom: 10px;
    }
  }
  .first{
    width: 50%;
    margin-left: 25%;
    padding: 5px;
    font-size: 18px;
    font-weight: bolder;
   }
  .sec{
    width: 50%;
    margin-left: 25%;
    padding: 5px;
   }
  .third{
    width: 50%;
    margin-left: 25%;
    font-size: 12px;
    padding: 5px;
    word-break: break-all;
   }

  .result-image{
    height : 300px;
    width : 100%;
   }

  .preview-image{
    height: 300px;

  }

  .loading{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 100px;
  }

  .two-button{
    width: unset !important;
  }

  .small-analyze{
    display: inline-block;
    margin: 10px;
  }

  @media (max-height: 700px)
	{ 
    .file-helper {
      height: 270px !important;
    }
    .preview-image{
      height: 270px !important;
    }
    .third{
      font-size: 10px !important;
    }
    .input{
      padding: 6px 10px;
    }
    .result-image{
      height: 270px !important;
    }
	}
</style>