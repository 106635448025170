<template>
	<div class="app" id="app">

		<div class="add-to-fade" id="addToFade">
		</div>
		<div id="addToIos" class="add-to">
			<img src="img/icon.png" class="logo"/>
			<div class="text bold" style="font-size: 16px !important;">Please add talos app to your
				home screen by following the guide in below.
			</div>
			<div class="row">
				<div class="right">
					<svg id="Group_820" data-name="Group 820" xmlns="http://www.w3.org/2000/svg" width="11.955" height="16.877" viewBox="0 0 11.955 16.877">
						<path id="Path_4" data-name="Path 4" d="M-1992.74,524.715v.789h3.2v10.375h-10.375V525.5h3.2v-.789h-3.986v11.954h11.955V524.715Z" transform="translate(2000.709 -519.791)" fill="#1261d7"/>
						<path id="Path_5" data-name="Path 5" d="M-1960.619,473.175v9.148h.79v-9.148l1.5,1.473.554-.563-2.446-2.406-2.446,2.406.554.563Z" transform="translate(1966.201 -471.68)" fill="#1261d7"/>
					</svg>
				</div>
				<div class="left" style="font-size: 12px;">1.Press <span style="color: #FF2D53; font-weight: 600">Share</span>
					Button at the bottom bar
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="row">
				<div class="right">
					<svg xmlns="http://www.w3.org/2000/svg" width="17.067" height="17.067" viewBox="0 0 17.067 17.067">
						<g id="Group_825" data-name="Group 825" transform="translate(792.399 -162.027)">
							<rect id="Rectangle_238" data-name="Rectangle 238" width="17.067" height="17.067" rx="3" transform="translate(-792.399 162.027)" fill="#6a6971"/>
							<path id="Path_6" data-name="Path 6" d="M-1972.088,932.344h-3.551v-3.551h-1.053v3.551h-3.551V933.4h3.551v3.551h1.053V933.4h3.551Z" transform="translate(1192.299 -762.292)" fill="#fff"/>
						</g>
					</svg>
				</div>
				<div class="left" style="font-size: 12px;">2.Press <span style="color: #FF2D53; font-weight: 600">Add To Home Screen</span>
					from list
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="row">
				<div class="right">
					<svg xmlns="http://www.w3.org/2000/svg" width="18.269" height="7.438" viewBox="0 0 18.269 7.438">
						<g id="Group_821" data-name="Group 821" transform="translate(0 0)">
							<path id="Path_7" data-name="Path 7" d="M-2030.716,1357.966h-1.5l-2.506,7.074h1.434l.616-1.819h2.4l.617,1.819h1.435Zm-1.566,4.073.818-2.547.829,2.547Z" transform="translate(2034.717 -1357.734)" fill="#1261d7"/>
							<path id="Path_8" data-name="Path 8" d="M-1958.595,1355.466v2.577a1.978,1.978,0,0,0-1.566-.688,2.515,2.515,0,0,0-2.506,2.78,2.572,2.572,0,0,0,2.587,2.769,2.055,2.055,0,0,0,1.566-.677v.545h1.314v-7.306Zm-1.314,6.2a1.367,1.367,0,0,1-1.354-1.526,1.393,1.393,0,0,1,1.354-1.556,1.379,1.379,0,0,1,1.355,1.556A1.388,1.388,0,0,1-1959.909,1361.671Z" transform="translate(1969.356 -1355.466)" fill="#1261d7"/>
							<path id="Path_9" data-name="Path 9" d="M-1892.745,1355.466v2.577a1.978,1.978,0,0,0-1.566-.688,2.515,2.515,0,0,0-2.506,2.78,2.572,2.572,0,0,0,2.586,2.769,2.055,2.055,0,0,0,1.566-.677v.545h1.314v-7.306Zm-1.314,6.2a1.367,1.367,0,0,1-1.354-1.526,1.393,1.393,0,0,1,1.354-1.556,1.379,1.379,0,0,1,1.354,1.556A1.388,1.388,0,0,1-1894.059,1361.671Z" transform="translate(1909.62 -1355.466)" fill="#1261d7"/>
						</g>
					</svg>
				</div>
				<div class="left" style="font-size: 12px;">3.Press <span style="color: #FF2D53; font-weight: 600">Add</span>
					at the top of screen
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="understand" @click="dissmiss()">
				I Understand
			</div>
		</div>

		<div id="addToAndroid" class="add-to">
			<img src="img/icon.png" class="logo"/>
			<div class="text bold" style="font-size: 16px !important;">
				Please add talos app to your
				home screen by following the guide in below.
			</div>
			<div class="row">
				<div class="right">
					<svg version="1.1" width="20" height="15" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
						<circle cx="256" cy="256" r="64"/>
						<circle cx="256" cy="448" r="64"/>
						<circle cx="256" cy="64" r="64"/>
					</svg>
				</div>
				<div class="left" style="font-size: 12px;">1.Press <span style="color: #FF2D53; font-weight: 600">Menu</span>
					Button at the top
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="row">
				<div class="right">
					<svg id="Icons"   viewBox="0 0 74 74" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="m53.5 72h-33a4 4 0 0 1 -4-4v-62a4 4 0 0 1 4-4h33a4 4 0 0 1 4 4v62a4 4 0 0 1 -4 4zm-33-68a2 2 0 0 0 -2 2v62a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2v-62a2 2 0 0 0 -2-2z"/><path d="m56.5 13h-39a1 1 0 0 1 0-2h39a1 1 0 0 1 0 2z"/><path d="m56.5 59h-39a1 1 0 0 1 0-2h39a1 1 0 0 1 0 2z"/><path d="m37 68.188a3.688 3.688 0 1 1 3.688-3.688 3.692 3.692 0 0 1 -3.688 3.688zm0-5.375a1.688 1.688 0 1 0 1.688 1.687 1.689 1.689 0 0 0 -1.688-1.687z"/><path d="m41 8.75h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/></svg>
				</div>
				<div class="left" style="font-size: 12px;">2.Press <span style="color: #FF2D53; font-weight: 600">Add To Home Screen</span>
					from list
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="row">
				<div class="right">
					<svg xmlns="http://www.w3.org/2000/svg" width="18.269" height="7.438" viewBox="0 0 18.269 7.438">
						<g id="Group_821" data-name="Group 821" transform="translate(0 0)">
							<path id="Path_7" data-name="Path 7" d="M-2030.716,1357.966h-1.5l-2.506,7.074h1.434l.616-1.819h2.4l.617,1.819h1.435Zm-1.566,4.073.818-2.547.829,2.547Z" transform="translate(2034.717 -1357.734)" fill="#1261d7"/>
							<path id="Path_8" data-name="Path 8" d="M-1958.595,1355.466v2.577a1.978,1.978,0,0,0-1.566-.688,2.515,2.515,0,0,0-2.506,2.78,2.572,2.572,0,0,0,2.587,2.769,2.055,2.055,0,0,0,1.566-.677v.545h1.314v-7.306Zm-1.314,6.2a1.367,1.367,0,0,1-1.354-1.526,1.393,1.393,0,0,1,1.354-1.556,1.379,1.379,0,0,1,1.355,1.556A1.388,1.388,0,0,1-1959.909,1361.671Z" transform="translate(1969.356 -1355.466)" fill="#1261d7"/>
							<path id="Path_9" data-name="Path 9" d="M-1892.745,1355.466v2.577a1.978,1.978,0,0,0-1.566-.688,2.515,2.515,0,0,0-2.506,2.78,2.572,2.572,0,0,0,2.586,2.769,2.055,2.055,0,0,0,1.566-.677v.545h1.314v-7.306Zm-1.314,6.2a1.367,1.367,0,0,1-1.354-1.526,1.393,1.393,0,0,1,1.354-1.556,1.379,1.379,0,0,1,1.354,1.556A1.388,1.388,0,0,1-1894.059,1361.671Z" transform="translate(1909.62 -1355.466)" fill="#1261d7"/>
						</g>
					</svg>
				</div>
				<div class="left" style="font-size: 12px;">3.Press <span style="color: #FF2D53; font-weight: 600">Add</span>
					from modal
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="understand" @click="dissmiss()">
				I understand
			</div>
		</div>

		<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
		<router-view/>
	</div>
</template>


<script>

	export default {
		name: 'App',
		data()
		{
			return {
				file: null,
			};
		},
		methods: {
			dissmiss()
			{
				localStorage.setItem("showPopUp",true);

				document.getElementById("addToFade").style.display    = 'none';
				document.getElementById("addToAndroid").style.display = 'none';
				document.getElementById("addToIos").style.display     = 'none';
			},
		},
		mounted()
		{
			if(!localStorage.getItem("showPopUp")){
				if (((!('standalone' in window.navigator)) && (window.navigator.standalone)) || (!window.matchMedia('(display-mode:standalone)').matches))
				{
					var userAgent = navigator.userAgent || navigator.vendor || window.opera;

					if (/android/i.test(userAgent))
					{

						document.getElementById("addToFade").style.display    = 'block';
						document.getElementById("addToAndroid").style.display = 'block';

					}
					if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
					{

						document.getElementById("addToFade").style.display = 'block';
						document.getElementById("addToIos").style.display  = 'block';
					}
				}
			}
		}
	};
</script>



<style lang="scss">

	.splide__pagination
	{
		bottom: -20px !important;
	}

	.error
	{
		font-size: 13px;
		color: #C9352D;
		text-align: center;
		padding: 5px;
	}

	.error-input
	{
		border: 1px solid #C9352D !important;
	}

	.one-two
	{
		color: #4A9C2E !important;
		fill: #4A9C2E !important;
	}

	.three-four
	{
		color: #6ABF4A !important;
		fill: #6ABF4A !important;
	}

	.five-six
	{
		color: #F8CF01 !important;
		fill: #F8CF01 !important;
	}

	.seven-eight
	{
		color: #BE2E2D !important;
		fill: #BE2E2D !important;
	}

	.nine-ten
	{
		color: #A1292A !important;
		fill: #A1292A !important;
	}

	.one-two-back
	{
		background-color: #4A9C2E !important;
	}

	.three-four-back
	{
		background-color: #6ABF4A !important;
	}

	.five-six-back
	{
		background-color: #F8CF01 !important;
	}

	.seven-eight-back
	{
		background-color: #BE2E2D !important;
	}

	.nine-ten-back
	{
		background-color: #A1292A !important;
	}

	*
	{
		box-sizing: border-box;
		font-family: open_sansregular !important;
		outline: none;
	}

	.app
	{
		min-height: 100vh;
		background-color: #FFFFFF;
	}

	a
	{
		text-decoration: none !important;
	}

	html, body, head
	{
		font-weight: 300;
		padding: 0;
		margin: 0;
		outline: none;
		border: 0;
		background: #F5F6F8;
		font-size: 15px;
	}

	html
	{
		height: 100%;
	}

	body
	{
		min-height: 100%;
	}

	.clearfix
	{
		clear: both;
	}

	$fontPath: '/fonts/iran_sans_serif/';
	$fontEnglishPath: '/fonts/open_sans/';

	@font-face
	{
		font-family: IRANSans-web;
		font-style: normal;
		font-weight: bold;
		src: url($fontPath + 'IRANSansWeb(FaNum)_Bold.eot');
		src: url($fontPath + 'IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),
		url($fontPath + 'IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),
		url($fontPath + 'IRANSansWeb(FaNum)_Bold.woff') format('woff'),
		url($fontPath + 'IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
	}

	@font-face
	{
		font-family: IRANSans-web;
		font-style: normal;
		font-weight: 500;
		src: url($fontPath + 'IRANSansWeb(FaNum)_Medium.eot');
		src: url($fontPath + 'IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),
		url($fontPath + 'IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),
		url($fontPath + 'IRANSansWeb(FaNum)_Medium.woff') format('woff'),
		url($fontPath + 'IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
	}

	@font-face
	{
		font-family: IRANSans-web;
		font-style: normal;
		font-weight: 300;
		src: url($fontPath + 'IRANSansWeb(FaNum)_Light.eot');
		src: url($fontPath + 'IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),
		url($fontPath + 'IRANSansWeb(FaNum)_Light.woff2') format('woff2'),
		url($fontPath + 'IRANSansWeb(FaNum)_Light.woff') format('woff'),
		url($fontPath + 'IRANSansWeb(FaNum)_Light.ttf') format('truetype');
	}

	@font-face
	{
		font-family: IRANSans-web;
		font-style: normal;
		font-weight: 200;
		src: url($fontPath + 'IRANSansWeb(FaNum)_UltraLight.eot');
		src: url($fontPath + 'IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),
		url($fontPath + 'IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),
		url($fontPath + 'IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),
		url($fontPath + 'IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
	}

	@font-face
	{
		font-family: IRANSans-web;
		font-style: normal;
		font-weight: normal;
		src: url($fontPath + 'IRANSansWeb(FaNum).eot');
		src: url($fontPath + 'IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
		url($fontPath + 'IRANSansWeb(FaNum).woff2') format('woff2'),
		url($fontPath + 'IRANSansWeb(FaNum).woff') format('woff'),
		url($fontPath + 'IRANSansWeb(FaNum).ttf') format('truetype');
	}

	@font-face
	{
		font-family: open_sansregular;
		src: url($fontEnglishPath +'OpenSans-Regular-webfont.eot');
		src: url($fontEnglishPath +'OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
		url($fontEnglishPath +'OpenSans-Regular-webfont.woff') format('woff'),
		url($fontEnglishPath +'OpenSans-Regular-webfont.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}


	.add-to-fade
	{
		display: none;
		z-index: 9999;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: .4;
		overflow-x: hidden;
	}

	.add-to
	{
		display: none;
		position: absolute;
		z-index: 9999;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		background-color: #FFFFFF;
		padding-top: 100px;
	}

	.logo
	{
		height: 80px;
		width: 80px;
		background-color: pink;
		margin: auto;
		left: 0;
		right: 0;
		display: block;
	}

	.text
	{
		user-select: none;
		text-align: center;
		font-size: 12px;
		padding: 5px
	}

	.bold
	{
		font-weight: 600;
	}

	.row
	{
		width: 70%;
		margin: auto;
		left: 0;
		right: 0;
		margin-top: 20px;
	}

	.left
	{
		float: left;
		user-select: none;
	}

	.right
	{
		float: right;
	}

	.clearfix
	{
		clear: both;
	}

	.understand
	{
		user-select: none;
		width: 150px;
		position: absolute;
		bottom: 50px;
		left: 0;
		right: 0;
		background-color: #FF008A;
		height: 55px;
		margin: auto;
		text-align: center;
		color: #FFFFFF;
		font-weight: 600;
		line-height: 50px;
		border-radius: 5px;

	}
</style>
