<template>
	<div class="user-layout">
		<router-view/>
	</div>
</template>


 
 <script>
 export default {
	name : 'Pc'
 }
 </script>
 

<style lang="scss" scoped>

	.user-layout{
		margin: 0; height: 100%; overflow: hidden
	}

</style>

